import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import LeadsAssignedService from '../../services/LeadsAssignedService';
import { Toast } from 'primereact/toast';
import ToastService from '../../services/ToastService';
import LeadService from '../../services/LeadService';



function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function EditLeadAssigned() {

const queryParam = useQuery();
const toast = useRef(null);
const toastService = new ToastService(toast); 
const { id } = useParams(); 
const navigate = useNavigate();
const [UserId] = useState(queryParam.get("userId") || AuthService.getCurrentUser().UserId);
const [activeTab, setActiveTab] = useState('edit');
const [leadHistory, setLeadHistory] = useState([]); 
const [oldLeadStatusId, setOldLeadStatusId] = useState(null); 


const [leadDetails, setLeadDetails] = useState({
  firstName: "",
  leadCode: "",
  email:"",
  contactNumber:"",

});


const [form, setForm] = useState({
    leadId: "",
    leadStatusId: "",
    propertyTypeId: "", 
    userId: UserId, 
    conversationComment:"", 
    leadAssignedDate:"",
    followUpDate:"",
    nextUpdate:"",
    followUpTime:""
  });

  const [formError, setFormError] = useState({});

  const LeadStatus = {
    HOTCLIENT: { leadStatusId: 1, leadStatusName: 'HotClient' },
    INTERESTED: { leadStatusId: 2, leadStatusName: 'Interested' },
    NOTINTERESTED: { leadStatusId: 3, leadStatusName: 'NotInterested' },
    PHONENOTPICKED: { leadStatusId: 4, leadStatusName: 'PhoneNotPicked' },
    INVALIDNUMBER: { leadStatusId: 5, leadStatusName: 'InvalidNumber' },
    CONTACTAFTERSOMETIME: { leadStatusId: 6, leadStatusName: 'ContactAfterSomeTime' },
    CALLBACKLATER: { leadStatusId: 7, leadStatusName: 'CallBackLater' },
    UPCOMINGVISIT: { leadStatusId: 8, leadStatusName: 'UpcomingVisit' },
    COMPLETE: { leadStatusId: 9, leadStatusName: 'Complete' },
    VISITED: { leadStatusId: 10, leadStatusName: 'Visited' }
  };

  const PropertyType = {
    ONEBHK: { propertyTypeId: 1, propertyTypeName: '1BHK' },
    TWOBHK: { propertyTypeId: 2, propertyTypeName: '2BHK' },
    THREEBHK: { propertyTypeId: 3, propertyTypeName: '3BHK' },
    FOURBHK: { propertyTypeId: 4, propertyTypeName: '4BHK' },
    FIVEBHK: { propertyTypeId: 5, propertyTypeName: '5BHK' },
    PLOT: { propertyTypeId: 6, propertyTypeName: 'Plot' },
    KOTHI: { propertyTypeId: 7, propertyTypeName: 'Kothi' },
    OTHER: { propertyTypeId: 8, propertyTypeName: 'Other' },
  };

 
  useEffect(() => {
    const fetchData = async () => {
      if (activeTab === 'leadhistory') {
        try {
          const result = await LeadService.getLeadHistoryByLeadId(id);
          setLeadHistory(result);
        } catch (error) {
          console.error('Error fetching lead history:', error);
          toastService.showError("Error", "Error fetching Lead History");
        }
      }
    };

    fetchData();
  }, [activeTab, id]);

  useEffect(() => {
    const fetchLeadsAssignedData = async () => {
      try {
        const data = await LeadsAssignedService.getLeadsAssignedById(id);
        setForm(data);
        setOldLeadStatusId(data.leadStatusId); 

         const leadInfo = await LeadService.getLeadById(data.leadId); 
         setLeadDetails({
          firstName: leadInfo.firstName,
             leadCode: leadInfo.leadCode,
             email:leadInfo.email,
             contactNumber:leadInfo.contactNumber,
         });

      } catch (error) {
        console.error('Error fetching leads assigned data:', error);
      } 
    };
  
    fetchLeadsAssignedData();
  }, [id]);
  

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,  
    });
  };
  

  const validateForm = () => {
    const errors = {};
    if (!form.conversationComment.trim()) errors.conversationComment = "Please enter the conversation comments.";
    if (!form.leadAssignedDate.trim()) errors.leadAssignedDate = "Please select lead assigned date.";
    if (!form.followUpDate.trim()) errors.followUpDate = "Please select follow up date.";
    if (!form.nextUpdate.trim()) errors.nextUpdate = "Please select next follow update.";
   
    setFormError(errors);

    if (Object.keys(errors).length > 0) {
      toastService.showError("Validation Error", "Please fill out all required fields ");
    }

    return Object.keys(errors).length === 0;
  };

  const onLeadSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    updateLeadAssigned();
  };


  const updateLeadAssigned = async () => {
    const formDataToSend = new FormData();
  
    if (!form.leadStatusId) {
      toastService.showError("Validation Error", "Please select a valid Lead Status.");
      return;
    }
  
    Object.keys(form).forEach(key => {
      formDataToSend.append(key, form[key]); 
    });
  
    try {      
      await LeadsAssignedService.updateLeadAssigned(id, formDataToSend); 
      toastService.showSuccess("Success", "Lead Assigned updated and status recorded successfully");
      setTimeout(() => {
        navigate(-1);
        // navigate("/leadassigned");
      }, 1000);
    } catch (error) {
      console.error("Error updating lead assigned and recording status:", error);
      toastService.showError("Error", "Failed to update lead assigned and record status");
    }
  };
  
  
  
  const getLeadStatusName = (statusId) => {
  const status = Object.values(LeadStatus).find(s => s.leadStatusId === statusId);
  return status ? status.leadStatusName : 'Unknown';
};


  return (
    <div>
          <Toast ref={toast} />
<br/>

<div className="lead-details">
    <h3 className="text-center">Assigned Lead Information</h3>
    
    <table className="table table-bordered">
        <tbody>
            <tr>
                <th scope="row">Lead Code:</th>
                <td>{leadDetails.leadCode}</td>
            </tr>
            <tr>
                <th scope="row">Lead Name:</th>
                <td>{leadDetails.firstName}</td>
            </tr>
            <tr>
                <th scope="row">Email:</th>
                <td>{leadDetails.email}</td>
            </tr>
            <tr>
                <th scope="row">Contact Number:</th>
                <td>{leadDetails.contactNumber}</td>
            </tr>
        </tbody>
    </table>
</div>




<br/>
      <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'edit' ? 'active' : ''}`}
              onClick={() => setActiveTab('edit')}
            >
              Edit
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'leadhistory' ? 'active' : ''}`}
              onClick={() => setActiveTab('leadhistory')}
            >
              Lead History
            </button>
          </li>
        </ul>

      {activeTab === 'edit' && (
      <form onSubmit={onLeadSubmit}>
        <br/>
              <div className="form-group row">
                <label className="col-lg-2" htmlFor="conversationcomments">
                       Conversation Comments
                  </label>
                  <div className="col-lg-4">
                      <textarea 
                        rows="4" 
                        name="conversationComment" 
                        id="conversationcomments" 
                        className={`form-control ${formError.conversationComment ? 'is-invalid' : ''}`}                       
                        onChange={changeHandler} 
                        value={form.conversationComment} 
                        />
                      {formError.conversationComment && <div className="invalid-feedback">{formError.conversationComment}</div>}   
                      <p></p> 
                  </div>

                  <label className="col-lg-2" htmlFor="leadAssignedDate">
                      Lead Assigned Date
                  </label>
                  <div className="col-lg-4">
                      <input 
                          type="text"
                          name="leadAssignedDate" 
                          id="leadAssignedDate" 
                          className={`form-control ${formError.leadAssignedDate ? 'is-invalid' : ''}`}
                          onChange={changeHandler} 
                          value={form.leadAssignedDate} 
                          disabled
                      />
                      {formError.leadAssignedDate && <div className="invalid-feedback">{formError.leadAssignedDate}</div>}   
                      <p></p> 
                  </div>

              </div>

              <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="followUpDate">
                          Follow Up
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="datetime-local" 
                            name="followUpDate" 
                            id="followUpDate"
                            className={`form-control ${formError.followUpDate ? 'is-invalid' : ''}`}
                            placeholder="Follow Up date" onChange={changeHandler}
                            value={form.followUpDate} 
                            />
                           {formError.followUpDate && <div className="invalid-feedback">{formError.followUpDate}</div>}   
                           <p></p>
                        </div>

                        <label className="col-lg-2 text-dark" htmlFor="nextupdate">
                        Next Follow Up 
                        </label>
                        <div className="col-lg-4">
                        <input 
                            type="datetime-local"
                            name="nextUpdate" 
                            id="nextupdate"
                            className={`form-control ${formError.nextUpdate ? 'is-invalid' : ''}`}
                            placeholder="Next FollowUp date" onChange={changeHandler}
                            value={form.nextUpdate} 
                            />
                           {formError.nextUpdate && <div className="invalid-feedback">{formError.nextUpdate}</div>}   
                           <p></p>
                        </div>
                    </div>

                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="followUpTime">
                     Next FollowUp Time
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="time" 
                            name="followUpTime" 
                            id="followUpTime"
                            className={`form-control ${formError.followUpTime ? 'is-invalid' : ''}`}
                            placeholder="Follow Up Time" onChange={changeHandler}
                            value={form.followUpTime} 
                            />
                           {formError.followUpTime && <div className="invalid-feedback">{formError.followUpTime}</div>}   
                           <p></p>
                        </div>
                        </div>

              <div className="form-group row">
                  <label className="col-lg-2" htmlFor="leadStatusId">
                      Lead Status
                  </label>
                  
                  <div className="col-lg-4">
                  <select
                    name="leadStatusId"
                    id="leadStatusId"
                    className={`form-control ${formError.leadStatusId ? 'is-invalid' : ''}`}
                    value={form.leadStatusId}
                    onChange={changeHandler}
                    >
                    <option value="">Select Lead Status</option>
                    <option value={LeadStatus.HOTCLIENT.leadStatusId}>{LeadStatus.HOTCLIENT.leadStatusName}</option>
                    <option value={LeadStatus.INTERESTED.leadStatusId}>{LeadStatus.INTERESTED.leadStatusName}</option>
                    <option value={LeadStatus.NOTINTERESTED.leadStatusId}>{LeadStatus.NOTINTERESTED.leadStatusName}</option>
                    <option value={LeadStatus.PHONENOTPICKED.leadStatusId}>{LeadStatus.PHONENOTPICKED.leadStatusName}</option>
                    <option value={LeadStatus.INVALIDNUMBER.leadStatusId}>{LeadStatus.INVALIDNUMBER.leadStatusName}</option>
                    <option value={LeadStatus.CONTACTAFTERSOMETIME.leadStatusId}>{LeadStatus.CONTACTAFTERSOMETIME.leadStatusName}</option>
                    <option value={LeadStatus.CALLBACKLATER.leadStatusId}>{LeadStatus.CALLBACKLATER.leadStatusName}</option>
                    <option value={LeadStatus.COMPLETE.leadStatusId}>{LeadStatus.COMPLETE.leadStatusName}</option>
                    <option value={LeadStatus.UPCOMINGVISIT.leadStatusId}>{LeadStatus.UPCOMINGVISIT.leadStatusName}</option>
                    <option value={LeadStatus.VISITED.leadStatusId}>{LeadStatus.VISITED.leadStatusName}</option>
                    </select>

                    {formError.leadStatusId && <div className="invalid-feedback">{formError.leadStatusId}</div>}   
                    <p></p> 
                  </div>

              <label className="col-lg-2" htmlFor="propertyTypeId">
                     Property Type
                  </label>
                  <div className="col-lg-4">
                       <select
                    name="propertyTypeId"
                    id="propertyTypeId"
                    className={`form-control ${formError.propertyTypeId ? 'is-invalid' : ''}`}
                    value={form.propertyTypeId}
                    onChange={changeHandler}
                    >
                    <option value="">Select Property Type</option>
                    <option value={PropertyType.ONEBHK.propertyTypeId}>{PropertyType.ONEBHK.propertyTypeName}</option>
                    <option value={PropertyType.TWOBHK.propertyTypeId}>{PropertyType.TWOBHK.propertyTypeName}</option>
                    <option value={PropertyType.THREEBHK.propertyTypeId}>{PropertyType.THREEBHK.propertyTypeName}</option>
                    <option value={PropertyType.FOURBHK.propertyTypeId}>{PropertyType.FOURBHK.propertyTypeName}</option>
                    <option value={PropertyType.FIVEBHK.propertyTypeId}>{PropertyType.FIVEBHK.propertyTypeName}</option>
                    <option value={PropertyType.PLOT.propertyTypeId}>{PropertyType.PLOT.propertyTypeName}</option>
                    <option value={PropertyType.KOTHI.propertyTypeId}>{PropertyType.KOTHI.propertyTypeName}</option>
                    <option value={PropertyType.OTHER.propertyTypeId}>{PropertyType.OTHER.propertyTypeName}</option>
                    </select>
                     {formError.propertyTypeId && <div className="invalid-feedback">{formError.propertyTypeId}</div>}   
                     <p></p>
                  </div>
              </div>
              <div className="col-md-12 text-center">
            <button type="submit" className="btn btn-save btn-dark text-white">Update</button>
            </div>
      </form>
      )}

      {activeTab === 'leadhistory' && (        
        <div>
          <br/>
          <h3>Lead History</h3>
          {leadHistory.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Old Lead Status</th>
                  <th>Comments</th>
                  <th>FollowUp Date</th>
                  <th>Next FollowUp</th>
                </tr>
              </thead>
              <tbody>
                {leadHistory.map((history, index) => (
                  <tr key={index}>
                    <td>{getLeadStatusName(history.leadStatusId)}</td>
                    <td>{history.comment}</td>
                    <td>{new Date(history.followUpDate).toLocaleDateString()}</td>
                    <td>{new Date(history.nextUpdate).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No lead history available.</p>
          )}
        </div>
      )}

      </div>
    )
  }

export default EditLeadAssigned