import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import AuthService from '../../services/AuthService'; 
import { Toast } from 'primereact/toast';
import LeadService from '../../services/LeadService';
import ToastService from '../../services/ToastService';
import { property } from 'lodash';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function EditLead() {
  const queryParam = useQuery();
  const toast = useRef(null);
  const { id } = useParams();  
  const navigate = useNavigate();
  const toastService = new ToastService(toast); 
  const [UserId] = useState(queryParam.get("userId") || AuthService.getCurrentUser().UserId);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    startDate:"",
    endDate:"",
    address:"",
    property:"",
    userId: UserId,
    id: id,
  });

  const [formError, setFormError] = useState({}); 

  useEffect(() => {
    const fetchLeadsData = async () => {
      try {
        const data = await LeadService.getLeadById(id);
        setForm(data);
      } catch (error) {
        console.error('Error fetching leads data:', error);
      } 
    };

    fetchLeadsData();
  }, [id]);
  
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForm(prevForm => ({ ...prevForm, [name]: value }));
  };
  

  const validateForm = () => {

    const errors = {};
    if (!form.firstName.trim()) errors.firstName = "Please enter the lead first name.";
    if (!form.email.trim()) errors.email = "Please enter the email.";
    if (!form.address.trim()) errors.address = "Please enter address.";
    if (!form.contactNumber) errors.contactNumber = "Please enter the contact number.";
    if (!form.startDate) errors.startDate = "Please enter the start date.";
    if (!form.endDate) errors.endDate = "Please enter the endDate .";
    
    setFormError(errors);

    if (Object.keys(errors).length > 0) {
      toastService.showWarning('Validation Error', 'Please fill out all required fields');
    }

    return Object.keys(errors).length === 0;
  };

  const onLeadSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    updateLead();
  };

  
  const updateLead = async () => {
    const formDataToSend = new FormData();
    Object.keys(form).forEach(key => {
          formDataToSend.append(key, form[key]);
        }
      );
    try {
      await LeadService.updateLead(id, formDataToSend); 
      toastService.showSuccess('Success', 'Lead updated successfully');
      setTimeout(() => {
      //  navigate("/leads");
        navigate(-1);
      }, 1000); 
    } catch (error) {
      console.error("Error updating lead:", error);
      toastService.showError('Error', 'Failed to update lead');
    }
  };


  return (
    <div>
      <Toast ref={toast} />

        <h3>Edit</h3>

      <form onSubmit={onLeadSubmit}>

      <div className="form-group row">
      <label className="col-lg-2" htmlFor="firstName">
                      First Name
                </label>
                <div className="col-lg-4">
                    <input 
                      type="text" 
                      name="firstName" 
                      id="firstName" 
                      className={`form-control ${formError.firstName ? 'is-invalid' : ''}`}
                      placeholder="First Name" 
                      onChange={changeHandler} 
                      value={form.firstName} 
                      />
                    {formError.firstName && <div className="invalid-feedback">{formError.firstName}</div>}   
                    <p></p> 
                </div>

                <label className="col-lg-2" htmlFor="lastName">
                      Last Name
                </label>
                <div className="col-lg-4">
                    <input 
                      type="text" 
                      name="lastName" 
                      id="lastName" 
                      className={`form-control ${formError.lastName ? 'is-invalid' : ''}`}
                      placeholder="Last Name" 
                      onChange={changeHandler} 
                      value={form.lastName} 
                      />
  
                </div>

            </div>

            <div className="form-group row">
            <label className="col-lg-2" htmlFor="txtemail">
                    Email
                </label>
                <div className="col-lg-4">
                    <input type="email" name="email" id="txtemail" 
                    className={`form-control ${formError.email ? 'is-invalid' : ''}`}
                    placeholder="Email" onChange={changeHandler}
                    value={form.email} 
                    />
                  {formError.email && <div className="invalid-feedback">{formError.email}</div>}   
                  <p></p> 
                </div>

            <label className="col-lg-2" htmlFor="contactNumber">
                    Contact Number
                </label>
                <div className="col-lg-4">
                    <input 
                    type="number" 
                    name="contactNumber" 
                    id="contactNumber"
                    className={`form-control ${formError.contactNumber ? 'is-invalid' : ''}`}
                    placeholder="Contact Number" onChange={changeHandler}
                    value={form.contactNumber} 
                     maxLength="13"
                    />
                    {formError.contactNumber && <div className="invalid-feedback">{formError.contactNumber}</div>}   
                    <p></p>
                </div>
            </div>

            <div className="form-group row">
            <label className="col-lg-2" htmlFor="startDate">
                  Start Date
                </label>
                <div className="col-lg-4">
                    <input 
                    disabled
                     type="datetime-local"
                    name="startDate" 
                    id="startDate"
                    className={`form-control ${formError.startDate ? 'is-invalid' : ''}`}
                      onChange={changeHandler}
                    value={form.startDate} 
                    />
                    {formError.startDate && <div className="invalid-feedback">{formError.startDate}</div>}   
                    <p></p>
                </div>

                <label className="col-lg-2" htmlFor="endDate">
                  End Date
                </label>
                <div className="col-lg-4">
                    <input 
                    disabled
                     type="datetime-local"
                    name="endDate" 
                    id="endDate"
                    className={`form-control ${formError.endDate ? 'is-invalid' : ''}`}
                      onChange={changeHandler}
                    value={form.endDate} 
                    />
                    {formError.endDate && <div className="invalid-feedback">{formError.endDate}</div>}   
                    <p></p>
                </div>
            </div>

              <div className="form-group row">

              <label className="col-lg-2" htmlFor="address">
                    Address
                </label>
                <div className="col-lg-4">
                    <input type="text" name="address" id="address" 
                    className={`form-control ${formError.address ? 'is-invalid' : ''}`}
                    placeholder="Address" onChange={changeHandler}
                    value={form.address} 
                    />
                  {formError.address && <div className="invalid-feedback">{formError.address}</div>}   
                  <p></p> 
                </div>

                <label className="col-lg-2" htmlFor="property">
                      Property
                </label>
                <div className="col-lg-4">
                    <input 
                    disabled
                      type="text" 
                      name="property" 
                      id="property" 
                      className={`form-control ${formError.property ? 'is-invalid' : ''}`}
                      placeholder="Property" 
                      onChange={changeHandler} 
                      value={form.property} 
                      />
  
                </div>

                </div>                   
                  
      <div className="col-md-12 text-center">
          <button type="submit" className="btn btn-save text-white btn-center">Update</button>
      </div>

      </form>


            </div>
  );
}

export default EditLead;